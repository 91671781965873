<template>
    <div class="info">
      <top-bar :title="'处理小事'" :left="true"></top-bar>

      <van-popup v-model="resultShow" position="bottom"  >
        <van-picker title="处理结果" show-toolbar :columns="resultOptions" value-key="label" @confirm="resultConfirm" @cancel="resultShow = false"  />
      </van-popup>
      <van-popup v-model="typeShow" position="bottom"  >
        <van-picker title="事件类别" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
      </van-popup>
      <van-popup v-model="managerShow" position="bottom"  >
        <van-picker title="推送到" show-toolbar :columns="managerList" value-key="label" @confirm="managerConfirm" @cancel="managerShow = false"  />
      </van-popup>

      <van-cell >
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">事件信息</span>
        </template>
      </van-cell>
      <van-cell title="事件类别"  :value="typeValue" @click="typeShow = !typeShow"/>
      <van-cell title="处理结果"  :value="resultValue" @click="resultShow = !resultShow"/>
      <van-cell title="推送到其他人员" :value="managerValue" v-if="dataForm.status == 10" @click="managerShow = !managerShow"/>
      <van-field v-model="dataForm.content" label="处理描述" placeholder="请输入" input-align="right" rows="4" type="textarea" v-if="dataForm.status == 0 || dataForm.status == 30"/>
      <div class="uploadBox">
        <van-uploader v-model="fileList"   style="padding:20px" v-if="dataForm.status == 0 || dataForm.status == 30" @delete="uploaddel" :after-read="uploadRead"/>
      </div>
      <van-row >
        <van-col :span="24" style="padding-top: 80px">
          <van-button type="info" size="large" round @click="submit">确定处理</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState,mapMutations } from 'vuex'
export default {
  data () {
    return {
      resultShow: false,
      typeShow: false,
      managerShow: false,
      value: '',
      detailData: {},
      resultValue: '请选择',
      typeValue: '请选择',
      managerValue: '请选择',
      resultOptions:[
        { label: "无法处理", value: "0" },
        { label: "推送", value: "10" },
        { label: "处理中", value: "20" },
        { label: "处理完成", value: "30" }
      ],
      typeList:[],
      managerList: [],
      dataForm:{
        status:'',
        type:'',
        manager: '',
        id: '',
        fileMessages:[],
        content: ''
      },
      fileList:[]
    }
  },
  computed:{...mapState(['problemData'])},
  components :{
    topBar
  },
  methods: {
    getDetail () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.problemData.id
        })
      }).then(({data})=> {
          if (data.code == 0) {
            this.typeValue = data.entityVO.typeStr
            this.dataForm.id =  data.entityVO.id
            this.dataForm.type = data.entityVO.type
            this.resultOptions.forEach((item,index)=> {
              if(item.value == data.entityVO.status) {
                  this.resultValue = this.resultOptions[index + 1].label
                  this.dataForm.status = this.resultOptions[index + 1].value
              }
            })
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
      })
    },
    submit () {
      if (this.dataForm.type == '') {return this.$toast.fail('失败:请先选择事件类别');}
      if (this.dataForm.status == '') {return this.$toast.fail('失败:请先选择处理结果');}
      if (this.dataForm.manager == '' && this.dataForm.status == 10) {return this.$toast.fail('失败:请先选择推送人员');}
      if (this.dataForm.content == '' && this.dataForm.status == 0 || this.dataForm.status == 0) {return this.$toast.fail('失败:请先输入处理描述');}
      this.$http({
          url: this.$http.adornUrl('/wxapp/problem/update'),
          method: 'post',
          data: this.$http.adornData({
            content: this.dataForm.content,
            fileMessages: this.dataForm.fileMessages,
            id: this.dataForm.id,
            solveTarget: this.dataForm.manager,
            status: this.dataForm.status,
            type: this.dataForm.type,
            orgId: this.$orgId
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success({message:'处理成功'})
            this.$router.push('/trifle')
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    getType() {
      this.$http({
          url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'post',
          params: this.$http.adornParams({
            orgId: this.$orgId,
            code: 'problemType'
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
              this.typeList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    getManager() {
      this.$http({
          url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'post',
          params: this.$http.adornParams({
            orgId: this.$orgId,
            code: 'managerType'
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
              this.managerList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
        // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.dataForm.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/problem/temporary');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.fileMessages.push(data.fileMessage)
            return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    resultConfirm(val) {
      this.resultValue = val.label
      this.dataForm.status = val.value
      this.resultShow = false
    },
    typeConfirm(val) {
      this.typeValue = val.label
      this.dataForm.type = val.value
      this.typeShow = false
    },
    managerConfirm(val) {
      this.managerValue = val.label
      this.dataForm.manager = val.value
      this.managerShow = false
    }
  },
  created() {
    this.getType()
    this.getManager()
    this.getDetail()
  }
}
</script>
<style>
  a {
  color: rgb(4, 50, 255);
}
.blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
    .van-field__control {
      text-align: right ;
    }
</style>
<style lang="scss" scoped>
.cont {
  .van-cell__value {
    width: 500px;
    flex: 2;
  }
}
  .van-divider{
    border-color: #000;
  }
  .info {
    margin-top: 100px;
  }
</style>
